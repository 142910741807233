import * as types from '../actions/actionTypes';

const initialState = {
    messages: []
};

const getLocalStorage = () => {
    let state = { ...initialState }
    if (localStorage.getItem('msg')) {
        state = JSON.parse(localStorage.getItem('msg'))
    }
    return state;
}

export default function reducer(state = getLocalStorage(), action) {
    let newState = { ...state }
    switch (action.type) {
        case types.SET_MESSAGES:
            newState = {
                ...state,
                messages: action.messages,
            };
            localStorage.setItem('msg', JSON.stringify(newState))
            return newState

        default:
            localStorage.clear()
            localStorage.setItem('msg', JSON.stringify(newState))
            return newState
    }
}