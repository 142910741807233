import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import {
  DeleteOutlined
} from '@ant-design/icons';
import { API_getAllProducts } from '../../../../services/products';
import { API_getProductTypes } from '../../../../services/productTypes';
import { setProducts } from '../../../../redux/actions/productsActions';
import { setProductTypes } from '../../../../redux/actions/productTypesActions';
import { API_getAllVehiclesTypes } from '../../../../services/vehiclesTypes';
import { setVehicles } from '../../../../redux/actions/vehiclesActions';
import { API_getAllInvoices, API_sendInvoice } from '../../../../services/invoices';
import MenuAdmin from '../MenuAdmin';
import { Navigate } from 'react-router-dom';

const Generate = () => {
  const dispatch = useDispatch();
  const [newProductTitle, setNewProductTitle] = useState('');
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const discountObj = { _id: -1, type: 1, title: 'Descuento', price: 0, v_id: '-1' }
  const [showingProducts, setShowingProducts] = useState([]);
  const [vehicleId, setVehicleId] = useState(null);
  const products = useSelector((state) => state.productsReducer.products)
  const productTypes = useSelector((state) => state.productTypesReducer.productTypes)
  const vehicleTypes = useSelector((state) => state.vehiclesReducer.vehicles)
  const [loadingProducts, setLoadingProducts] = useState(false)
  const [formData, setFormData] = useState({
    matricula: '',
    marca: '',
    modelo: '',
    tipoVehiculo: [],
    telefono: '',
    email: '',
    nombre: '',
    nif: '',
    producto: [],
    tipoPago: 'bizum',
    descripcion: '',
    documento: '',
    tipoDocumento: ''
  });
  const { Option } = Select;
  const [errors, setErrors] = useState({
    matricula: '',
    tipoVehiculo: '',
    telefono: '',
    nombre: '',
    producto: ''
  });

  const getProducts = async () => {
    setLoadingProducts(true)
    const resAllProducs = await API_getAllProducts()
    if (resAllProducs?.data?.products?.length > 0) {
      dispatch(setProducts(resAllProducs?.data?.products))
      setShowingProducts([...resAllProducs.data.products, discountObj])
    }
    const resAllProductsTypes = await API_getProductTypes()
    if (resAllProductsTypes?.data?.productTypes?.length > 0)
      dispatch(setProductTypes(resAllProductsTypes.data.productTypes))
    const resAllVehicleTypes = await API_getAllVehiclesTypes();
    dispatch(setVehicles(resAllVehicleTypes.data.vehicleTypes))
    setLoadingProducts(false)
  }

  const clearData = () => {
    setFormData({
      matricula: '',
      marca: '',
      modelo: '',
      tipoVehiculo: [],
      telefono: '',
      email: '',
      nombre: '',
      nif: '',
      producto: [],
      tipoPago: 'bizum',
      descripcion: '',
      documento: '',
      tipoDocumento: ''
    });
    setErrors({
      matricula: '',
      tipoVehiculo: '',
      telefono: '',
      nombre: '',
      producto: ''
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validación básica
    const newErrors = {};
    if (formData.tipoVehiculo?.length === 0) {
      newErrors.tipoVehiculo = 'El tipo de vehiculo es requerido';
    }
    if (!formData.matricula.trim()) {
      newErrors.matricula = 'La matrícula es requerida';
    }
    if (!formData.nombre.trim()) {
      newErrors.nombre = 'El nombre es requerido';
    }
    if (formData.producto?.length === 0) {
      newErrors.producto = 'El producto es requerido';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    API_sendInvoice({ ...formData, tipoVehiculo: vehicleTypes.filter((vt) => vt._id === formData.tipoVehiculo)[0].type })
      .then(async () => {
        const res = await API_getAllInvoices();
        if (res?.data?.invoices?.length > 0)
          dispatch({ type: 'SET_INVOICES', invoices: res.data.invoices });
        setShouldRedirect(true)
      })
      .catch(() => {
        setShouldRedirect(true)
      })
  };

  const handleChangePrice = (id, newPrice) => {
    const newProducto = formData.producto.map((product) => {
      if (product._id === id)
        return { ...product, price: newPrice };
      return product;
    })
    setFormData({ ...formData, producto: newProducto })
  }

  const handleDeleteProduct = (product) => {
    setFormData({ ...formData, producto: [...formData.producto.filter((pr) => pr._id !== product._id)] })
  }

  const getTotalPrice = () => {
    let total = 0;
    if (formData.producto?.length > 0) {
      formData.producto.forEach((product) => {
        total += (!product.price || isNaN(product.price)) ? 0 : parseInt(product.price, 10);
      })
    }
    return total;
  }

  const getIGIC = () => (getTotalPrice() * 0.07).toFixed(2);

  const getTotalPriceWithIGIC = () => parseInt(getTotalPrice(), 10) + parseFloat(getIGIC());

  useEffect(() => {
    if (!loadingProducts && (products?.length === 0 || productTypes?.length === 0))
      getProducts()
    else
      setShowingProducts([...products, discountObj])
  }, [])

  if (shouldRedirect) return <Navigate to="/admin/invoices" />;

  if (loadingProducts || !products || !productTypes)
    return <div>Cargando...</div>

  return (
    <>
      <MenuAdmin />
      <section className="py-20 flex justify-center">
        <div className="container mx-auto px-4">
          <form className="max-w-lg mx-auto bg-white p-8 rounded-lg shadow-lg" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block mb-2">Tipo de Vehículo</label>
              <Select
                showSearch
                className={`w-full h-11 border ${errors.tipoVehiculo ? 'border-red-500' : 'border-gray-300'} rounded`}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                value={formData.tipoVehiculo}
                onChange={(value) => {
                  setFormData({ ...formData, tipoVehiculo: value, producto: [] })
                  setErrors({ ...errors, tipoVehiculo: '' });
                  const foundVehicleType = vehicleTypes.filter((vt) => vt._id === value)[0]
                  setVehicleId(foundVehicleType.v_id)
                }}
              >
                {vehicleTypes.map((option, index) => (
                  <Option key={option.type} value={option._id} selected={index === 0}>
                    {option.type}
                  </Option>
                ))}
              </Select>
              {errors.tipoVehiculo && <p className="text-red-500 text-sm mt-1">{errors.tipoVehiculo}</p>}
            </div>
            <div className="mb-4">
              <label className="block mb-2">Matrícula</label>
              <input
                type="text"
                name="matricula"
                value={formData.matricula}
                onChange={handleChange}
                className={`w-full px-4 py-2 border ${errors.matricula ? 'border-red-500' : 'border-gray-300'} rounded`}
              />
              {errors.matricula && <p className="text-red-500 text-sm mt-1">{errors.matricula}</p>}
            </div>
            <div className="mb-4">
              <label className="block mb-2">Marca</label>
              <input
                type="text"
                name="marca"
                value={formData.marca}
                onChange={handleChange}
                className={`w-full px-4 py-2 border rounded`}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Modelo</label>
              <input
                type="text"
                name="modelo"
                value={formData.modelo}
                onChange={handleChange}
                className={`w-full px-4 py-2 border rounded`}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Teléfono</label>
              <input
                type="tel"
                name="telefono"
                value={formData.telefono}
                onChange={handleChange}
                className={`w-full px-4 py-2 border ${errors.telefono ? 'border-red-500' : 'border-gray-300'} rounded`}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Correo Electrónico</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className={`w-full px-4 py-2 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded`}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Nombre</label>
              <input
                type="text"
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
                className={`w-full px-4 py-2 border ${errors.nombre ? 'border-red-500' : 'border-gray-300'} rounded`}
              />
              {errors.nombre && <p className="text-red-500 text-sm mt-1">{errors.nombre}</p>}
            </div>
            <div className="mb-4 flex flex-col">
              <label className="block w-full mb-2">Documento</label>
              <div className="flex w-full">
                <Select
                  className="w-1/5 h-11 border border-gray-300 rounded-l-md"
                  value={formData.tipoDocumento}
                  onChange={(value) => setFormData({ ...formData, tipoDocumento: value })}
                >
                  <Option value="DNI">DNI</Option>
                  <Option value="NIF">NIF</Option>
                  <Option value="CIF">CIF</Option>
                </Select>
                <input
                  type="text"
                  name="documento"
                  value={formData.documento}
                  onChange={handleChange}
                  className="w-4/5 h-11 px-4 py-2 border border-gray-300 border-l-0 rounded-r-md"
                />
              </div>
              {errors.documento && <p className="text-red-500 text-sm mt-1">{errors.documento}</p>}
            </div>
            <div className="mb-4">
              <label className="block mb-2">Tipo de Pago</label>
              <select
                name="tipoPago"
                value={formData.tipoPago}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded"
              >
                <option value="bizum">Bizum</option>
                <option value="tarjeta">Tarjeta</option>
                <option value="transferencia">Transferencia</option>
                <option value="efectivo">Efectivo</option>
              </select>
            </div>
            {
              vehicleId && (
                <div className="mb-4">
                  <label className="block mb-2">Producto</label>
                  <Select
                    showSearch
                    className={`w-full h-11 border ${errors.producto ? 'border-red-500' : 'border-gray-300'} rounded`}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.children?.toString().toLowerCase().includes(input.toLowerCase())
                    }
                    value={""}
                    onSearch={(value) => setNewProductTitle(value)}
                    onChange={(value) => {
                      if (value === "new") {
                        if (newProductTitle.trim() !== "") {
                          const newProduct = {
                            _id: `temp-${Date.now()}`,
                            type: 1,
                            title: newProductTitle,
                            price: 0,
                            v_id: vehicleId
                          };
                          setFormData({ ...formData, producto: [...formData.producto, newProduct] });
                          setShowingProducts([...showingProducts, newProduct]);
                        }
                      } else {
                        setFormData({ ...formData, producto: [...formData.producto, ...showingProducts.filter((pr) => pr._id === value)] });
                        setShowingProducts(showingProducts.filter((sp) => sp._id !== value));
                        setErrors({ ...errors, producto: "" });
                      }
                    }}
                  >
                    {showingProducts
                      .filter((sp) => sp?._id === -1 || sp?.v_id.indexOf(vehicleId) !== -1)
                      .map((option) => (
                        <Option key={option._id} value={option._id}>
                          {option.title}
                        </Option>
                      ))
                    }
                    {newProductTitle && !showingProducts.some(sp => sp.title.toLowerCase() === newProductTitle.toLowerCase()) && (
                      <Option key="new" value="new">
                        ➕ Añadir "{newProductTitle}"
                      </Option>
                    )}
                  </Select>
                  {
                    formData.producto?.length > 0 && (
                      <div className='w-full flex flex-col justify-start mt-2'>
                        {
                          formData.producto.map((pr) => (
                            <div key={`input__${pr._id}`} className='text-sm mt-2 flex flex-row justify-between items-center rounded w-full'>
                              <span className='w-2/3'>{pr.title}</span>
                              <input className='border rounded w-1/5 p-1 text-right' type='number' onChange={(e) => handleChangePrice(pr._id, e.target.value)} value={isNaN(pr.price) ? 0 : pr.price} />€
                              <DeleteOutlined className="text-additional" onClick={() => handleDeleteProduct(pr)} />
                            </div>
                          ))
                        }
                      </div>
                    )
                  }
                  {errors.producto && <p className="text-red-500 text-sm mt-1">{errors.producto}</p>}
                </div>
              )
            }
            {
              getTotalPrice() > 0 && (
                <>
                  <div className="mb-4">
                    <label className="block mb-2">Total sin IGIC</label>
                    <input
                      type="text"
                      name="precio"
                      value={getTotalPrice() + ' €'}
                      disabled
                      className={`w-full px-4 py-2 border border-gray-300 rounded`}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block mb-2">IGIC (7%)</label>
                    <input
                      type="text"
                      name="igic"
                      value={getIGIC() + ' €'}
                      disabled
                      className={`w-full px-4 py-2 border border-gray-300 rounded`}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block mb-2">Total con IGIC</label>
                    <input
                      type="text"
                      name="precioWithIGIC"
                      value={getTotalPriceWithIGIC() + ' €'}
                      disabled
                      className={`w-full px-4 py-2 border border-gray-300 rounded`}
                    />
                  </div>
                </>
              )
            }
            <div className="mb-4">
              <label className="block mb-2">Descripción</label>
              <textarea
                name="descripcion"
                value={formData.descripcion}
                onChange={handleChange}
                className={`w-full px-4 py-2 border ${errors.descripcion ? 'border-red-500' : 'border-gray-300'} rounded`}
                rows="4"
              ></textarea>
              {errors.descripcion && <p className="text-red-500 text-sm mt-1">{errors.descripcion}</p>}
            </div>
            <div className="flex w-full justify-between items-center">
              <button type="submit" className="bg-main text-white px-6 py-2 rounded">Generar</button>
              <span onClick={clearData} className="bg-main text-white px-6 py-2 rounded">Limpiar</span>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Generate;
