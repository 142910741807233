import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es';
import { API_deleteMessage, API_getAllMessages } from '../../../../services/messages';
import MenuAdmin from '../MenuAdmin';
import { setMessages } from '../../../../redux/actions/messagedActions';
import { Modal } from 'antd';

const MessageDetail = () => {
    const [shouldRedirect, setShouldRedirect] = useState(false)

    const messages = useSelector((state) => state.messagesReducer.messages);
    const { messageId } = useParams();
    const [message, setMessage] = useState(null);
    const dispatch = useDispatch();
    const { confirm } = Modal;

    const handleGetMessages = async () => {
        const res = await API_getAllMessages();
        if (res?.data?.messages)
            dispatch(setMessages(res.data.messages))
    };

    const handleSearchMessage = () => {
        const found = messages.find((i) => i._id === messageId);
        if (!found) setShouldRedirect(true);

        setMessage(found);
    }

    const showConfirm = (id) => {
        confirm({
            title: '¿Estás seguro de que deseas borrar este mensaje?',
            content: 'Esta acción no se puede deshacer.',
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteMessage(id)
            },
            onCancel() { }
        });
    };

    const handleDeleteMessage = async (id) => {
        await API_deleteMessage(id);
        handleGetMessages()
        setShouldRedirect(true)
    };

    useEffect(() => {
        if (messages?.length === 0)
            setShouldRedirect(true)
        else
            handleSearchMessage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (shouldRedirect) return <Navigate to="/admin/messages" />;

    if (!message) return <p>Cargando...</p>;

    return (
        <>
            <MenuAdmin />
            <div className="message-detail-container max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mt-20">
                <h1 className="text-2xl font-bold mb-4 text-main">Detalles del Mensaje</h1>
                <div className="message-detail">
                    <div className="flex justify-between w-full mb-4">
                        <strong>Nombre:</strong> <span>{message.name}</span>
                    </div>
                    <div className="flex justify-between w-full mb-4">
                        <strong>Email:</strong> <span>{message.email}</span>
                    </div>
                    <div className="flex justify-between w-full mb-4">
                        <strong>Teléfono:</strong> <span>{message.phone}</span>
                    </div>
                    <div className="flex justify-between w-full mb-4">
                        <strong>Fecha:</strong> <span>{moment(message.date, 'Do MMMM YYYY, h:mm:ss a').format('Do MMMM YYYY, h:mm:ss a')}</span>
                    </div>
                    <div className="mb-8 h-60">
                        <p><strong>Mensaje:</strong></p>
                        <textarea className='w-full mt-4 h-full border border-gray-600 rounded resize-none p-2 text-gray-600 text-md' value={message.message} disabled />
                    </div>
                </div>
                <button
                    className="bg-additional text-white px-4 py-2 rounded mt-4 w-full md:w-auto"
                    onClick={() => showConfirm(message._id)}
                >
                    Borrar Mensaje
                </button>
            </div>
        </>
    );
};

export default MessageDetail;
