import React, { useEffect, useState } from 'react';
import { Modal, Select } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import { API_deleteInvoice, API_getAllInvoices } from '../../../../services/invoices';
import MenuAdmin from '../MenuAdmin';

function Invoices() {
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [showingInvoices, setShowingInvoices] = useState([]);
  
  const invoices = useSelector((state) => state.invoicesReducer.invoices);
  const invalidList = useSelector((state) => state.invoicesReducer.invalidList);
  const dispatch = useDispatch();
  const { confirm } = Modal;
  const { Option } = Select;
  
  moment.locale('es');

  useEffect(() => {
    if (invalidList) handleGetInvoices();
  }, [invalidList]);

  useEffect(() => {
    setShowingInvoices(invoices);
    if (years.length === 0) setAvailableYears();
  }, [invoices]);

  const handleGetInvoices = async () => {
    const res = await API_getAllInvoices();
    if (res?.data?.invoices) {
      dispatch({ type: 'SET_INVOICES', invoices: res.data.invoices });
    }
  };

  const handleDeleteInvoice = async (id) => {
    await API_deleteInvoice(id);
    handleGetInvoices();
  };

  const handleChangeYear = (selectedYear) => {
    if (selectedYear) {
      const filteredInvoices = invoices.filter(inv => inv.creationDate.includes(selectedYear));
      setShowingInvoices(filteredInvoices);
      setMonths(Array.from(new Set(filteredInvoices.map(inv => moment(inv.creationDate).format('MMMM')))));
    } else {
      setMonths([]);
      setShowingInvoices(invoices);
    }
  };

  const handleChangePlate = (selectedPlate) => {
    if (selectedPlate) {
      setShowingInvoices(showingInvoices.filter(inv => inv.matricula === selectedPlate));
    } else {
      setShowingInvoices(invoices);
    }
  };

  const handleChangeMonth = (selectedMonth) => {
    if (selectedMonth) {
      setShowingInvoices(invoices.filter(inv => moment(inv.creationDate).format('MMMM') === selectedMonth));
    } else {
      setShowingInvoices(invoices);
    }
  };

  const setAvailableYears = () => {
    setYears(Array.from(new Set(invoices.map(inv => moment(inv.creationDate).format('YYYY')))));
  };

  const getPlates = () => {
    return Array.from(new Set(showingInvoices.map(inv => inv.matricula)));
  };

  const showConfirm = (id) => {
    confirm({
      title: '¿Estás seguro de que deseas borrar esta factura?',
      content: 'Esta acción no se puede deshacer.',
      okText: 'Sí',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteInvoice(id);
      },
    });
  };

  if (invoices.length === 0) {
    return (
      <>
        <MenuAdmin />
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg pt-20">
          <h1 className="text-2xl font-bold mb-4 text-main">No existen facturas</h1>
        </div>
      </>
    );
  }

  return (
    <>
      <MenuAdmin />
      <section id="invoices" className="py-14 flex w-full justify-center pt-20">
        <div className="w-full text-center">
          <div className="w-full flex items-center justify-center flex-col overflow-x-auto">
            <div className="bg-secondary text-white min-w-full md:min-w-0 md:w-1/2 mb-12 p-2 flex flex-row justify-start items-start">
              {getPlates().length > 0 && (
                <Select
                  placeholder="Matrícula"
                  showSearch
                  allowClear
                  className="w-1/3 mr-1 h-11 border border-gray-300 rounded uppercase"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onClear={() => handleChangePlate('')}
                  onChange={(value) => handleChangePlate(value)}
                >
                  {getPlates().map((plate, index) => (
                    <Option key={`${index}-${plate}`} value={plate} className="uppercase">
                      {plate}
                    </Option>
                  ))}
                </Select>
              )}
              {years.length > 0 && (
                <Select
                  placeholder="Año"
                  showSearch={false}
                  className="w-1/3 mr-1 h-11 border border-gray-300 rounded uppercase"
                  optionFilterProp="children"
                  allowClear
                  onClear={() => handleChangeYear('')}
                  onChange={(value) => handleChangeYear(value)}
                >
                  {years.map((year, index) => (
                    <Option key={`${index}-${year}`} value={year}>
                      {year}
                    </Option>
                  ))}
                </Select>
              )}
              {months.length > 0 && (
                <Select
                  placeholder="Mes"
                  showSearch={false}
                  allowClear
                  className="w-1/3 h-11 border border-gray-300 rounded uppercase"
                  optionFilterProp="children"
                  onClear={() => handleChangeMonth('')}
                  onChange={(value) => handleChangeMonth(value)}
                >
                  {months.map((month, index) => (
                    <Option key={`${index}-${month}`} value={month}>
                      {month}
                    </Option>
                  ))}
                </Select>
              )}
            </div>
            <table className="min-w-full md:min-w-0 md:w-1/2 mb-12 bg-white">
              <thead>
                <tr>
                  <th className="py-2 px-2 bg-secondary text-white border-b md:text-left">Número de Factura</th>
                  <th className="py-2 px-2 bg-secondary text-white border-b md:text-left">Matrícula</th>
                  <th className="py-2 px-2 bg-secondary text-white border-b md:text-left">Fecha</th>
                  <th className="py-2 px-2 bg-secondary text-white border-b md:text-right">Total</th>
                </tr>
              </thead>
              <tbody>
                {showingInvoices.sort((a, b) => moment(b.creationDate) - moment(a.creationDate)).map((invoice, index) => (
                  <tr key={`${invoice.id}-${index}`} className="cursor-pointer hover:bg-gray-200">
                    <td className="py-2 px-2 text-sm md:text-md border-b md:text-left max-w-[4rem] overflow-hidden text-ellipsis whitespace-nowrap">
                      <Link to={`/admin/invoice/${invoice.numInvoice}`} className="text-main hover:underline visited:text-main">
                        {invoice.numInvoice}
                      </Link>
                    </td>
                    <td className="py-2 px-2 text-sm md:text-md border-b md:text-left max-w-[8rem] overflow-hidden text-ellipsis whitespace-nowrap">
                      {invoice.matricula}
                    </td>
                    <td className="py-2 px-2 text-sm md:text-md border-b md:text-left">
                      {moment(invoice.creationDate).format('D-MM-YYYY')}
                    </td>
                    <td className="py-2 px-2 text-sm md:text-md border-b md:text-right flex justify-end items-center">
                      {`${invoice.totalIgic}€`}
                      <DeleteOutlined className="text-additional text-white px-4 py-2 rounded" onClick={() => showConfirm(invoice._id)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}

export default Invoices;
