import axios from 'axios';
const { BASE_URL = "https://www.limcar.es/api" } = process.env;

export function API_sendInvoice(payload) {
    return axios.post(`${BASE_URL}/invoices/send`, { payload }, {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('t')}`
      }
    })
      .then(res => res)
      .catch((err) => {
        if (err?.response?.data?.error === "Invalid token") {
          sessionStorage.removeItem('t')
          window.location.href = "/"
        }
      })
}

export function API_getAllInvoices() {
    return axios.get(`${BASE_URL}/invoices/searchAll`, {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('t')}`
      }
    })
      .then(res => res)
      .catch((err) => {
        if (err?.response?.data?.error === "Invalid token") {
          sessionStorage.removeItem('t')
          window.location.href = "/"
        }
      })
}

export function API_deleteInvoice(id) {
  return axios.delete(`${BASE_URL}/invoices/delete/${id}`, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('t')}`
    }
  })
    .then(res => res)
    .catch((err) => {
      if (err?.response?.data?.error === "Invalid token") {
        sessionStorage.removeItem('t')
        window.location.href = "/"
      }
    })
}
