import React from 'react'

function Footer() {
    return (
        <footer className="bg-blue-800 text-white py-6">
            <div className="container mx-auto text-center">
                <p>&copy; 2024 LIMCAR. Todos los derechos reservados.</p>
            </div>
        </footer>
    )
}

export default Footer