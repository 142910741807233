import reportWebVitals from './reportWebVitals';
import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import configureStore from './redux/store/configureStore';
import AppRoutes from './routes';
import 'tailwindcss/tailwind.css';
import './index.css';

const store = configureStore();
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <Router>
            <AppRoutes />
        </Router>
    </Provider>
)

reportWebVitals();
