import Home from './Home';
import Login from './Login';
import Admin from './Admin/components/MenuAdmin';
import AllServices from './AllServices';

export {
    Home,
    Login,
    Admin,
    AllServices
}