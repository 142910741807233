import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    UserOutlined,
    SettingOutlined,
    LogoutOutlined,
    PhoneOutlined
} from '@ant-design/icons';
import { API_secure } from '../services/auth';
import { useDispatch } from 'react-redux';
import { logout } from '../redux/actions/userActions';
import logo from '../assets/logo_letras.png';

function Menu() {
    const dispatch = useDispatch();
    const [isLogged, setIsLogged] = useState(false);

    const checkToken = async () => {
        if (!sessionStorage.getItem('t')) {
            setIsLogged(false);
        } else {
            const res = await API_secure();
            setIsLogged(res.status === 200);
        }
    };

    const handleLogout = () => {
        dispatch(logout());
        setIsLogged(false);
        sessionStorage.removeItem('t');
    };

    useEffect(() => { checkToken() }, []);

    return (
        <nav className="fixed top-0 left-0 z-20 w-full bg-white py-2 px-4">
            <div className="container mx-auto flex justify-between items-center">
                <Link to="/" className="logo w-18 text-main font-bold text-xl">
                    <img src={logo} alt="logo" className='h-8 md:h-14 bg-white md:p-4 p-2' />
                </Link>

                <div className="flex items-center gap-4 ml-2 md:ml-0">
                    <a href="tel:+34671863195" className="bg-main h-7 w-full text-white p-4 sm:px-4 font-bold uppercase rounded flex flex-row justify-center items-center"><PhoneOutlined
                        className="rotate-90 mr-2" /> <span className='md:block hidden'>Pide tu cita</span></a>
                    {
                        isLogged ? (
                            <>
                                <Link to="/admin/generate"
                                    className="py-1 px-2 text-main rounded hover:bg-gray-200"><SettingOutlined /></Link>
                                <LogoutOutlined className="p-2 text-main rounded hover:bg-gray-200"
                                    onClick={handleLogout} />
                            </>
                        ) : (
                            <Link to="/login" className="py-1 px-2 text-main rounded hover:bg-gray-200"><UserOutlined /></Link>
                        )
                    }
                </div>
            </div>
        </nav>
    );
}

export default Menu;
