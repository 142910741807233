import React from 'react';
import { Link } from 'react-router-dom';

const Vehicles = ({ vehicleTypes }) => {
    const imagesPerVehicle = {
        1: 'coche.webp',
        2: 'moto.jpeg',
        3: 'barco.jpg',
        4: 'caravana.jpg',
        5: 'suv.jpeg',
        6: 'furgoneta.jpeg'
    }
    const vehicles = vehicleTypes.map((vt) => ({
        image: imagesPerVehicle[vt.v_id], ...vt
    }))

    return (
        <section className="w-full h-auto text-center relative bg-gray-800 py-20">
            <h2 className="text-5xl font-bold text-white mb-12">Con qué trabajamos</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 px-4">
                {vehicles.map((vehicle, index) => (
                    <div>
                        <span className='text-lg font-bold text-2xl text-white mb-12'>{vehicle.type}</span>
                        <div key={vehicle._id} className="relative group">
                            <img
                                src={`assets/${vehicle.image}`}
                                alt={`Vehículo ${index + 1}`}
                                className="w-full h-64 object-cover transition duration-300 ease-in-out group-hover:blur-sm"
                            />
                            <div className="absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-60 opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out">
                                <Link to={`/all-services/${vehicle.v_id}`} className="bg-blue-600 text-white px-4 py-2 rounded">Ver más</Link>
                            </div>
                            <div className="absolute bottom-4 left-1/2 font-bold text-2xl transform -translate-x-1/2 bg-gray-900 bg-opacity-70 text-white px-2 py-1 rounded">
                                Desde {`${vehicle.minPrice} €`}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Vehicles;
