import * as types from '../actions/actionTypes';

const initialState = {
    invoices: [],
    invalidList: true
};

const getLocalStorage = () => {
    let state = { ...initialState }
    if (localStorage.getItem('inv')) {
        state = JSON.parse(localStorage.getItem('inv'))
    }
    return state;
}

export default function reducer(state = getLocalStorage(), action) {
    let newState = { ...state }
    switch (action.type) {
        case types.SET_INVOICES:
            newState = {
                ...state,
                invoices: action.invoices,
                invalidList: false,
            };
            localStorage.setItem('inv', JSON.stringify(newState))
            return newState

        case types.SET_INVALID_LIST:
            newState = {
                ...state,
                invalidList: action.invalidList,
            };
            localStorage.setItem('inv', JSON.stringify(newState))
            return newState

        default:
            localStorage.clear()
            localStorage.setItem('inv', JSON.stringify(newState))
            return newState
    }
}