import React, { useEffect, useState, useRef } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es';
import { useReactToPrint } from 'react-to-print';
import MenuAdmin from '../MenuAdmin';
import { API_getAllInvoices } from '../../../../services/invoices';
import './InvoiceDetail.css'; // Asegúrate de importar los estilos

const InvoiceDetail = () => {
    const { numInvoice } = useParams();
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const invoices = useSelector((state) => state.invoicesReducer.invoices);
    const invalidList = useSelector((state) => state.invoicesReducer.invalidList);
    const dispatch = useDispatch();
    moment.locale('es');

    const handleGetInvoices = async () => {
        const res = await API_getAllInvoices();
        if (res?.data?.invoices?.length > 0)
            dispatch({ type: 'SET_INVOICES', invoices: res.data.invoices });
        else
            setShouldRedirect(true);
    };

    useEffect(() => {
        if (invalidList) handleGetInvoices();

        const invoice = invoices.find((i) => i.numInvoice === numInvoice);

        if (invoices?.length > 0 && !invoice) {
            setShouldRedirect(true);
        }

        setSelectedInvoice(invoice);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoices?.length, invalidList]);

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        documentTitle: `${selectedInvoice?.numInvoice}_${selectedInvoice?.matricula}`,
        content: () => componentRef.current,
    });

    if (shouldRedirect) return <Navigate to="/admin/invoices" />;

    if (!selectedInvoice) return <p>Loading...</p>;

    return (
        <>
            <MenuAdmin />
            <div className="invoice-container max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mt-20">
                <div ref={componentRef} className="printable">
                    <div className="invoice-header flex flex-col md:flex-row items-center justify-between mb-6">
                        <div className='logo-container flex flex-col w-full md:w-1/4 justify-center items-center mb-4 md:mb-0'>
                            <img src="/assets/logo.png" alt="Logo" className="h-36 w-36" />
                            <p className="w-full text-center text-gray-600 text-sm"><strong>C. Batalla del Ebro, 69, 35013 Las Palmas de Gran Canaria</strong></p>
                            <p className="w-full text-center text-gray-600 text-sm"><strong>+34 671 863 195</strong></p>
                            <p className="w-full text-center text-gray-600 text-sm"><strong>limcar20199@gmail.com</strong></p>
                        </div>
                        <div className="info-container text-center md:text-right w-full md:w-3/4">
                            <h1 className="text-2xl md:text-3xl font-bold text-secondary">Factura nº <strong>{selectedInvoice.numInvoice}</strong></h1>
                            <p className="w-full text-gray-600 text-right text-md"><strong>{moment(selectedInvoice.creationDate).format('Do MMMM YYYY')}</strong></p>
                            <p className="w-full text-gray-600 text-right text-sm"><strong>45354598R</strong></p>
                            <p className="w-full text-gray-600 text-right text-sm"><strong>José Miguel Huroz Quintana</strong></p>
                        </div>
                    </div>
                    <div className="invoice-info mb-6">
                        <div className="invoice-header flex flex-col md:flex-row items-center justify-between mb-6">
                            <div className='flex flex-col w-full md:w-1/2 justify-center items-start'>
                                <h1 className="text-xl md:text-2xl font-bold text-secondary"><strong>Datos cliente</strong></h1>
                                {
                                    selectedInvoice.nombre && <p className="mb-2">Nombre: <strong>{selectedInvoice.nombre}</strong></p>
                                }
                                {
                                    selectedInvoice.telefono && <p className="mb-2">Teléfono: <strong>{selectedInvoice.telefono}</strong></p>
                                }
                                {
                                    selectedInvoice.email && <p className="mb-2">Email: <strong>{selectedInvoice.email}</strong></p>
                                }
                                {
                                    selectedInvoice.documento && <p className="mb-2"><span>{selectedInvoice.tipoDocumento}</span>: <strong>{selectedInvoice.documento}</strong></p>
                                }
                            </div>
                            <div className='vehicle-container text-right flex flex-col w-full md:w-1/2 justify-center items-start md:items-end'>
                                <h1 className="text-xl text-right md:text-2xl font-bold text-secondary"><strong>Datos vehículo</strong></h1>
                                <p className="mb-2 text-right">Matrícula: <strong>{selectedInvoice.matricula}</strong></p>
                                <p className="mb-2 text-right">Tipo de vehículo: <strong>{selectedInvoice.tipoVehiculo}</strong></p>
                                {
                                    selectedInvoice.marca && <p className="mb-2 text-right">Marca: <strong>{selectedInvoice.marca}</strong></p>
                                }
                                {
                                    selectedInvoice.modelo && <p className="mb-2 text-right">Modelo: <strong>{selectedInvoice.modelo}</strong></p>
                                }
                                <p className="mb-2 text-right">Tipo de pago: <strong className='capitalize'>{selectedInvoice.tipoPago}</strong></p>
                                {
                                    selectedInvoice.tipoPago === 'transferencia' && <p className="mb-2 text-right">Cuenta: <strong className='capitalize'>ES74 0182 5342 7302 0242 3157</strong></p>
                                }
                            </div>
                        </div>
                    </div>
                    <table className="min-w-full mb-6 bg-white border-secondary">
                        <thead>
                            <tr>
                                <th className="py-2 px-2 bg-secondary border-secondary text-left text-white border">Producto</th>
                                <th className="py-2 px-2 bg-secondary border-secondary text-right text-white border">Precio</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedInvoice.producto.map((prod, index) => (
                                <tr key={index}>
                                    <td className="py-2 px-2 border border-secondary text-left">{prod.title}</td>
                                    <td className="py-2 px-2 border border-secondary text-right">{isNaN(prod.price) ? 0 : `${prod.price}€`}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="invoice-totals mb-6 w-full flex flex-col justify-start items-end">
                        <p className="mb-2"><strong>Total (sin IGIC):</strong> {selectedInvoice.totalNoIgic}€</p>
                        <p className="mb-2"><strong>IGIC ({Math.round(selectedInvoice.igic * 100)}%):</strong> {(selectedInvoice.totalIgic - selectedInvoice.totalNoIgic).toFixed(2)}€</p>
                        <p className="mb-2"><strong>Total (con IGIC):</strong> {selectedInvoice.totalIgic}€</p>
                    </div>
                </div>
                {selectedInvoice.descripcion && (
                    <div className='w-full flex flex-col justify-start items-start min-h-24 mb-6'>
                        <h1 className="text-xl text-right md:text-2xl font-bold text-secondary"><strong>Observaciones</strong></h1>
                        <textarea className='w-full h-full border border-gray-600 rounded resize-none p-2 text-gray-600 text-md' value={selectedInvoice.descripcion} disabled />
                    </div>
                )}
                <button onClick={handlePrint} className="bg-secondary text-white px-4 py-2 rounded mt-4">Exportar a PDF</button>
            </div>
        </>
    );
};

export default InvoiceDetail;
