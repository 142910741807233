import React, { useState, useEffect } from "react";

const CookiesConsent = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem("cookiesConsent");
        if (!consent) {
            setIsVisible(true);
        } else {
            handleCookieAction(consent);
        }
    }, []);

    const handleCookieAction = (consent) => {
        if (consent === "accepted") {
            enableAdSense();
        } else if (consent === "rejected") {
            disableAdSense();
        }
    };

    const enableAdSense = () => {
        console.log("AdSense activado.");
    };

    const disableAdSense = () => {
        console.log("AdSense desactivado. No se cargarán cookies.");
    };

    const handleAccept = () => {
        localStorage.setItem("cookiesConsent", "accepted");
        setIsVisible(false);
        handleCookieAction("accepted");
    };


    if (!isVisible) return null;

    return (
        <>
            <div className="fixed inset-0 bg-neutral-900 bg-opacity-75 z-40 transition-opacity duration-300" style={{ opacity: 1 }}></div>
            <div className="fixed bottom-0 left-0 right-0 bg-neutral-900 text-white p-4 flex flex-col md:flex-row justify-between items-center shadow-lg z-40">
                <div className="text-sm md:text-base">
                    <p>
                        Este sitio utiliza cookies para ofrecer anuncios personalizados a través del servicio de Google AdSense. Al aceptar, consientes el uso de cookies conforme a nuestra {" "}
                        <a href="/privacy-policy" className="underline text-blue-400 hover:text-blue-300" target="_blank" rel="noopener noreferrer">
                            Política de Privacidad
                        </a>
                        .
                    </p>
                </div>
                <div className="mt-4 ml-1 md:mt-0 flex space-x-4">
                    <button onClick={handleAccept} className="bg-neutral-800 hover:text-gold-dark border border-2 border-gold-dark text-white font-semibold py-2 px-4 rounded">
                        Aceptar
                    </button>
                </div>
            </div>
        </>
    );
};

export default CookiesConsent;
