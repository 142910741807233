import React, { useEffect, useState } from 'react';
import { Modal, Select, Table, Input } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import { API_deleteInvoice, API_getAllInvoices } from '../../../../services/invoices';
import MenuAdmin from '../MenuAdmin';

function Invoices() {
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [matriculaFilter, setMatriculaFilter] = useState('');
  const [documentoFilter, setDocumentoFilter] = useState('');
  const [tipoPagoFilter, setTipoPagoFilter] = useState(null);
  const [numInvoiceFilter, setNumInvoiceFilter] = useState('');
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [showingInvoices, setShowingInvoices] = useState([]);

  const invoices = useSelector((state) => state.invoicesReducer.invoices);
  const invalidList = useSelector((state) => state.invoicesReducer.invalidList);
  const dispatch = useDispatch();
  const { confirm } = Modal;
  const { Option } = Select;

  moment.locale('es');

  useEffect(() => {
    if (invalidList) handleGetInvoices();
  }, [invalidList]);

  useEffect(() => {
    setShowingInvoices(invoices);
    setAvailableYears();
  }, [invoices]);

  const handleGetInvoices = async () => {
    const res = await API_getAllInvoices();
    if (res?.data?.invoices) {
      dispatch({ type: 'SET_INVOICES', invoices: res.data.invoices });
    }
  };

  const handleDeleteInvoice = async (id) => {
    await API_deleteInvoice(id);
    handleGetInvoices();
  };

  const setAvailableYears = () => {
    const yearsSet = new Set(invoices.map(inv => moment(inv.creationDate).format('YYYY')));
    setYears([...yearsSet]);
  };

  const showConfirm = (id) => {
    confirm({
      title: '¿Estás seguro de que quieres eliminar esta factura?',
      onOk() {
        handleDeleteInvoice(id);
      },
    });
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
    setSelectedMonth(null);
  };

  const handleFilterInvoices = () => {
    let filteredInvoices = invoices;

    if (selectedYear) {
      filteredInvoices = filteredInvoices.filter(inv => moment(inv.creationDate).format('YYYY') === selectedYear);
    }

    if (selectedMonth) {
      filteredInvoices = filteredInvoices.filter(inv => moment(inv.creationDate).format('MMMM') === selectedMonth);
    }

    if (matriculaFilter) {
      filteredInvoices = filteredInvoices.filter(inv => inv.matricula.toLowerCase().includes(matriculaFilter.toLowerCase()));
    }

    if (documentoFilter) {
      filteredInvoices = filteredInvoices.filter(inv => inv.documento.toLowerCase().includes(documentoFilter.toLowerCase()));
    }

    if (tipoPagoFilter) {
      filteredInvoices = filteredInvoices.filter(inv => inv.tipoPago === tipoPagoFilter);
    }

    if (numInvoiceFilter) {
      filteredInvoices = filteredInvoices.filter(inv => inv.numInvoice.toLowerCase().includes(numInvoiceFilter.toLowerCase()));
    }

    setShowingInvoices(filteredInvoices);
    setMonths(Array.from(new Set(filteredInvoices.map(inv => moment(inv.creationDate).format('MMMM')))));
  };

  useEffect(() => {
    handleFilterInvoices();
  }, [selectedYear, selectedMonth, matriculaFilter, documentoFilter, tipoPagoFilter, numInvoiceFilter]);

  const columns = [
    {
      title: 'Número de Factura',
      dataIndex: 'numInvoice',
      sorter: (a, b) => a.numInvoice.localeCompare(b.numInvoice),
      render: (text) => <Link to={`/admin/invoice/${text}`} className="text-blue-500 hover:underline">{text}</Link>,
    },
    {
      title: 'Matrícula',
      dataIndex: 'matricula',
      sorter: (a, b) => a.matricula.localeCompare(b.matricula),
    },
    {
      title: 'Documento',
      dataIndex: 'documento',
      sorter: (a, b) => a.documento.localeCompare(b.documento),
    },
    {
      title: 'Tipo de Pago',
      dataIndex: 'tipoPago',
      sorter: (a, b) => a.tipoPago.localeCompare(b.tipoPago),
      render: (text) => <span className="capitalize">{text}</span>,
    },
    {
      title: 'Fecha',
      dataIndex: 'creationDate',
      sorter: (a, b) => moment(a.creationDate).unix() - moment(b.creationDate).unix(),
      render: (text) => moment(text).format('D-MM-YYYY'),
    },
    {
      title: 'Total',
      dataIndex: 'totalIgic',
      sorter: (a, b) => a.totalIgic - b.totalIgic,
      render: (text) => `${text}€`,
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      render: (_, record) => (
        <DeleteOutlined className="text-red-500 cursor-pointer hover:text-red-700" onClick={() => showConfirm(record._id)} />
      ),
    },
  ];

  return (
    <>
      <MenuAdmin />
      <section id="invoices" className="py-14 flex w-full justify-center pt-20">
        <div className="w-full max-w-5xl p-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
            <Select className="w-full" placeholder="Filtrar por año" onChange={handleYearChange} allowClear>
              {years.map((year) => (
                <Option key={year} value={year}>{year}</Option>
              ))}
            </Select>
            <Select className="w-full" placeholder="Filtrar por mes" onChange={setSelectedMonth} allowClear disabled={!selectedYear} value={selectedMonth}>
              {months.map((month) => (
                <Option key={month} value={month}>{month}</Option>
              ))}
            </Select>
            <Input className="w-full" placeholder="Filtrar por matrícula" value={matriculaFilter} onChange={(e) => setMatriculaFilter(e.target.value)} />
            <Input className="w-full" placeholder="Filtrar por documento" value={documentoFilter} onChange={(e) => setDocumentoFilter(e.target.value)} />
            <Select className="w-full" placeholder="Filtrar por tipo de pago" onChange={setTipoPagoFilter} allowClear>
              {['transferencia', 'bizum', 'tarjeta', 'efectivo'].map((tipo) => (
                <Option className="capitalize" key={tipo} value={tipo}>{tipo}</Option>
              ))}
            </Select>
            <Input className="w-full" placeholder="Filtrar por número de factura" value={numInvoiceFilter} onChange={(e) => setNumInvoiceFilter(e.target.value)} />
          </div>
          <Table columns={columns} dataSource={showingInvoices} pagination={{ pageSize: 10 }} rowKey="id" className="shadow-lg rounded-lg overflow-hidden" />
        </div>
      </section>
    </>
  );
}

export default Invoices;