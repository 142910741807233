import * as types from '../actions/actionTypes';

const initialState = {
    products: []
};

const getLocalStorage = () => {
    let state = { ...initialState }
    if (localStorage.getItem('p')) {
        state = JSON.parse(localStorage.getItem('p'))
    }
    return state;
}

export default function reducer(state = getLocalStorage(), action) {
    let newState = { ...state }
    switch (action.type) {
        case types.SET_PRODUCTS:
            newState = {
                ...state,
                products: action.products,
            };
            localStorage.setItem('p', JSON.stringify(newState))
            return newState
        default:
            localStorage.clear()
            localStorage.setItem('p', JSON.stringify(newState))
            return newState
    }
}