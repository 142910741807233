import userReducer from './userReducer';
import productsReducer from './productsReducer';
import productTypesReducer from './productTypesReducer'
import vehiclesReducer from './vehiclesReducer'
import invoicesReducer from './invoicesReducer'
import messagesReducer from './messagesReducer'

export default {
    userReducer,
    productsReducer,
    productTypesReducer,
    vehiclesReducer,
    invoicesReducer,
    messagesReducer
};