import React, { useState } from 'react';
import { API_sendMessage } from '../services/messages';
import { Alert } from 'antd';
import { PhoneOutlined, ShopOutlined } from '@ant-design/icons';

function Contact() {
    const [showAlert, setShowAlert] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const [errors, setErrors] = useState({
        name: '',
        emailOrPhone: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors({
            ...errors,
            [name]: '',
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newErrors = {
            name: '',
            emailOrPhone: '',
            message: '',
        };

        if (!formData.name) {
            newErrors.name = 'El nombre es obligatorio.';
        }

        if (!formData.message) {
            newErrors.message = 'El mensaje es obligatorio.';
        }

        if (!formData.email && !formData.phone) {
            newErrors.emailOrPhone = 'Por favor, ingresa un correo electrónico o un teléfono.';
        }

        setErrors(newErrors);

        if (Object.values(newErrors).some(error => error)) {
            return;
        }

        if (formData.message.length <= 500) {
            await API_sendMessage(formData);
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);
            setFormData({
                name: '',
                email: '',
                phone: '',
                message: '',
            });
        }
    };

    const abrirGoogleMaps = () => {
        const direccionFormateada = encodeURIComponent(`C. Batalla del Ebro, 69, 35013 Las Palmas de Gran Canaria, Las Palmas`);
        const url = `https://www.google.com/maps/search/?api=1&query=${direccionFormateada}`;
        window.open(url, '_blank');
    }

    return (
        <section id="contact" className="bg-gray-200 py-8">
            <div className="container mx-auto text-center">
                <section id="address" className="text-center text-main">
                    <p className="text-lg font-semibold mb-4 cursor-pointer" onClick={ abrirGoogleMaps }><ShopOutlined
                        className='mr-2'/>{ `C. Batalla del Ebro, 69, 35013 Las Palmas de Gran Canaria, Las Palmas` }
                    </p>
                </section>
                <section id="phone" className="text-center text-main my-10">
                    <h2 className='text-3xl font-bold text-main flex justify-center items-center flex-col md:flex-row'>
                        Llámanos al <a href="tel:+34671863195" className='ml-0 md:ml-2'>+34 671 863 195</a>
                    </h2>
                    <h2 className="text-3xl font-bold text-main">o contáctanos por mensaje</h2>
                </section>

                <form className="max-w-xl mx-auto" onSubmit={ handleSubmit }>
                    <div className="mb-4">
                        <input
                            type="text"
                            name="name"
                            placeholder="Nombre"
                            className={ `w-full px-4 py-2 border ${ errors.name ? 'border-red-500' : 'border-gray-300' } rounded` }
                            value={ formData.name }
                            onChange={ handleChange }
                        />
                        { errors.name && <p className="text-red-500 text-left">{ errors.name }</p> }
                    </div>
                    <div className="mb-4">
                        <input
                            type="text"
                            name="email"
                            placeholder="Correo Electrónico"
                            className={ `w-full px-4 py-2 border ${ errors.emailOrPhone ? 'border-red-500' : 'border-gray-300' } rounded` }
                            value={ formData.email }
                            onChange={ handleChange }
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            type="text"
                            name="phone"
                            placeholder="Teléfono"
                            className={ `w-full px-4 py-2 border ${ errors.emailOrPhone ? 'border-red-500' : 'border-gray-300' } rounded` }
                            value={ formData.phone }
                            onChange={ handleChange }
                        />
                        { errors.emailOrPhone && <p className="text-red-500 text-left">{ errors.emailOrPhone }</p> }
                    </div>
                    <div className="mb-4">
                        <textarea
                            name="message"
                            placeholder="Mensaje"
                            className={ `w-full px-4 py-2 border ${ errors.message ? 'border-red-500' : 'border-gray-300' } rounded` }
                            value={ formData.message }
                            onChange={ handleChange }
                        ></textarea>
                        <span
                            className={ `flex justify-start items-start pl-1 w-full ${ formData.message.length > 500 ? 'text-red-500' : 'text-main' }` }>{ formData.message.length } / 500</span>
                        { errors.message && <p className="text-red-500 text-left">{ errors.message }</p> }
                    </div>
                    { showAlert && (
                        <Alert
                            className='mb-4'
                            message="El mensaje ha sido enviado correctamente."
                            type="success"
                            closable
                            onClose={ () => setShowAlert( false ) }
                        />
                    ) }
                    <div>
                        <button type="submit" className="bg-main text-white px-6 py-2 rounded">Enviar</button>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default Contact;
