import React from "react";

const PrivacyPolicy = () => {
    return (
        <div className="bg-white text-neutral-800 p-8 font-sans leading-relaxed">
            <h1 className="text-4xl font-bold mb-6 text-neutral-900">Política de Privacidad</h1>

            <p className="mb-6">
                Esta Política de Privacidad explica cómo recopilamos, usamos y protegemos tu información personal cuando visitas nuestro sitio web.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-3 text-neutral-900">Información que Recopilamos</h2>
            <ul className="list-disc pl-6 space-y-2 text-neutral-700">
                <li>Datos de navegación como dirección IP, navegador y dispositivo.</li>
                <li>Información recopilada por cookies, como preferencias del usuario y anuncios personalizados.</li>
            </ul>

            <h2 className="text-2xl font-semibold mt-6 mb-3 text-neutral-900">Uso de Cookies</h2>
            <p className="mb-4">
                Usamos cookies para personalizar los anuncios que ves en colaboración con Google AdSense. Estas cookies ayudan a mostrar anuncios relevantes basados en tus intereses.
            </p>
            <p className="mb-6">
                Para más información sobre cómo Google utiliza las cookies en sus anuncios, visita la página de{" "}
                <a
                    href="https://policies.google.com/technologies/ads"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 font-medium hover:text-blue-800 underline"
                >
                    Privacidad y Condiciones de Google
                </a>
                .
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-3 text-neutral-900">Servicios de Terceros</h2>
            <p className="mb-6 text-neutral-700">
                Utilizamos servicios de terceros, como Google AdSense, para mostrar anuncios personalizados. Estos servicios pueden recopilar y procesar datos según sus propias políticas de privacidad.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-3 text-neutral-900">Derechos del Usuario</h2>
            <p className="mb-6 text-neutral-700">
                Como usuario, tienes derecho a acceder, rectificar o eliminar tus datos personales. Si deseas ejercer estos derechos, por favor, contacta con nosotros en{" "}
                <a href="mailto:jsantado@gmail.com" className="text-blue-600 font-medium hover:text-blue-800 underline">
                    jsantado@gmail.com
                </a>
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-3 text-neutral-900">Cambios en la Política de Privacidad</h2>
            <p className="text-neutral-700">
                Nos reservamos el derecho de actualizar esta política en cualquier momento. Notificaremos cualquier cambio en esta página.
            </p>
        </div>
    );
};

export default PrivacyPolicy;
