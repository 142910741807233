import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import {
  UpCircleFilled
} from '@ant-design/icons';

import Menu from '../../components/Menu';
import Contact from '../../components/Contact';
import Footer from '../../components/Footer';
import Vehicles from './Vehicles';
import { useDispatch, useSelector } from 'react-redux';
import { API_getAllVehiclesTypes } from '../../services/vehiclesTypes';
import { setVehicles } from '../../redux/actions/vehiclesActions';

function Home() {
  const dispatch = useDispatch();
  const [showArrow, setShowArrow] = useState(false)
  const vehicleTypes = useSelector((state) => state.vehiclesReducer.vehicles)

  const scrollUp = () => {
    window.scrollTo(0, 0)
  }

  const getVehicleTypes = async () => {
    const resAllVehicleTypes = await API_getAllVehiclesTypes();
    dispatch(setVehicles(resAllVehicleTypes.data.vehicleTypes))
  }

  useEffect(() => {
    getVehicleTypes()
    scrollUp()
  }, [])

  window.addEventListener('scroll', function () {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setShowArrow(scrollTop > 0)
  });

  return (
    <>
      <Menu />
      <section className="w-full h-[100vh] text-center background-hero relative">
        <div className="absolute top-0 left-0 w-full h-[100vh] bg-black opacity-60" />
        <div
            className="absolute top-0 left-0 z-10 w-full h-full flex flex-col justify-center items-center text-white pt-20 md:pt-40">
          <h1 className="text-5xl font-bold mb-4">Limpieza Profesional de Vehículos</h1>
          <p className="text-xl mb-8">¡Mantén tu vehículo impecable con nuestros servicios de limpieza!</p>

          <div className="w-full py-8 px-4 text-center text-main">
            <Link to="/all-services" className="bg-blue-600 text-white px-6 py-2 rounded">
              Servicios disponibles
            </Link>
          </div>
        </div>
      </section>
      {
        vehicleTypes && vehicleTypes?.length > 0 && (
          <Vehicles vehicleTypes={vehicleTypes} />
        )
      }
      <Contact />
      <Footer />
      {
        showArrow && (
          <UpCircleFilled onClick={scrollUp} className="block fixed bottom-32 right-8 pointer md:hidden text-main text-4xl" />
        )
      }
    </>
  )
}

export default Home
