import axios from 'axios';
const { BASE_URL = "https://www.limcar.es/api" } = process.env;

export function API_login(user) {
    return axios.post(`${BASE_URL}/auth/login`, user)
      .then(res => res)
      .catch((err) => err)
}

export function API_secure() {
  return axios.get(`${BASE_URL}/auth/secure`, {
    headers: {
      'Authorization': 'Bearer ' + sessionStorage.getItem('t')
    }
  })
   .then(res => res)
   .catch((err) => err)
}