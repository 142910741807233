import * as types from './actionTypes';

export function logout() {
    return { type: types.LOGOUT };
}

export function setUser(user) {
    return { type: types.SET_USER, user };
}


