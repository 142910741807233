import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { API_getAllProducts } from '../../services/products';
import { API_getProductTypes } from '../../services/productTypes';
import { API_getAllVehiclesTypes } from '../../services/vehiclesTypes';
import Contact from '../../components/Contact';
import Footer from '../../components/Footer';
import { setProducts } from '../../redux/actions/productsActions';
import { setProductTypes } from '../../redux/actions/productTypesActions';
import { setVehicles } from '../../redux/actions/vehiclesActions';
import Menu from '../../components/Menu';

function AllServices() {
    const dispatch = useDispatch();
    const { vehicleId } = useParams();
    const vehicleTypes = useSelector((state) => state.vehiclesReducer.vehicles);
    const products = useSelector((state) => state.productsReducer.products);
    const productTypes = useSelector((state) => state.productTypesReducer.productTypes);
    const [loadingProducts, setLoadingProducts] = useState(false);
    const [selectedVehicleTypes, setSelectedVehicleTypes] = useState([]);

    const getProducts = async () => {
        setLoadingProducts(true);
        const resAllProducs = await API_getAllProducts();
        if (resAllProducs?.data?.products?.length > 0)
            dispatch(setProducts(resAllProducs?.data?.products));
        const resAllProductsTypes = await API_getProductTypes();
        if (resAllProductsTypes?.data?.productTypes?.length > 0)
            dispatch(setProductTypes(resAllProductsTypes.data.productTypes));
        const resAllVehicleTypes = await API_getAllVehiclesTypes();
        dispatch(setVehicles(resAllVehicleTypes.data.vehicleTypes));
        setLoadingProducts(false);
    };

    const getTypeTitle = (type) => productTypes.find((productType) => productType.type === type)?.title;

    const getProductsByType = (type, v_id) => products.filter((product) => product.type === type && product.v_id.indexOf(v_id) !== -1);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!loadingProducts && (products?.length === 0 || productTypes?.length === 0))
            getProducts();
        if (selectedVehicleTypes?.length === 0 && vehicleTypes.length > 0) {
            if (vehicleId) {
                setSelectedVehicleTypes([...vehicleTypes.filter((vt) => vt.v_id === vehicleId)]);
            } else {
                setSelectedVehicleTypes([...vehicleTypes]);
            }
        }
    }, [loadingProducts, products, productTypes, vehicleTypes, vehicleId]);

    if (loadingProducts || !products || !productTypes)
        return <div className="text-center py-20">Cargando...</div>;

    return (
        <>
            <Menu />
            <section id="services" className="pt-14 flex w-full justify-center my-20">
                <div className="container text-center px-4">
                    <h1 className="text-4xl font-bold text-main">Nuestros Servicios</h1>
                    <span className='text-md font-bold mb-8 w-full h-8 flex justify-center items-center text-red-500'>
                        Nota: Todos los precios indicados son sin IGIC.
                    </span>
                    {selectedVehicleTypes.map((vehicle, vIndex) => (
                        <div key={`${vehicle.id}-${vIndex}`} className="w-full mb-12">
                            <h2 className="w-full bg-secondary text-3xl font-bold text-white justify-center h-12 items-center flex">
                                {vehicle.type}
                            </h2>
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                                {productTypes.map((productType, pIndex) => (
                                    <div key={`${productType.id}-${pIndex}`} className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                                        <h3 className="text-2xl font-bold mb-4">{getTypeTitle(productType.type)}</h3>
                                        <table className="w-full mb-2">
                                            <thead>
                                                <tr>
                                                    <th className="py-2 px-2 w-2/3 bg-secondary text-white border-b">Título</th>
                                                    <th className="py-2 px-2 w-1/3 bg-secondary text-white border-b">Precio desde</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getProductsByType(productType.type, vehicle.v_id).map((service, sIndex) => (
                                                    <tr key={`${service.id}-${sIndex}`}>
                                                        <td className="py-2 px-2 w-2/3 border-b">{service.title}</td>
                                                        <td className="py-2 px-2 w-1/3 border-b">{isNaN(service.price) ? service.price?.indexOf('/') > 0 ? `${service.price}€` : service.price : `${service.price}€`}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                    <div className="flex my-4 flex-wrap w-full justify-center items-center">
                        <Link to="/" className="mt-4 w-full sm:w-auto text-center bg-blue-600 text-white py-2 px-6 rounded hover:bg-blue-700 transition-colors duration-300">
                            Volver
                        </Link>
                    </div>
                </div>
            </section>
            <Contact />
            <Footer />
        </>
    );
}

export default AllServices;
