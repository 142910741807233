import axios from 'axios';
const { BASE_URL = "https://www.limcar.es/api" } = process.env;

export const API_sendMessage = async (payload) => {
    try {
        const response = await axios.post(`${BASE_URL}/messages`, {payload});
        return response;
    } catch (error) {
        console.error('Error sending messages:', error);
        return null;
    }
};

export const API_getAllMessages = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/messages`, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('t')}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.error === "Invalid token") {
            sessionStorage.removeItem('t')
            window.location.href = "/"
        }
    }
};

export const API_deleteMessage = async (id) => {
    try {
        const response = await axios.delete(`${BASE_URL}/messages/${id}`, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('t')}`
            }
        });
        return response;
    } catch (error) {
        if (error?.response?.data?.error === "Invalid token") {
            sessionStorage.removeItem('t')
            window.location.href = "/"
        }
    }
};
