
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Home, Login, AllServices } from './screens';
import Generate from './screens/Admin/components/Generate';
import Invoices from './screens/Admin/components/Invoices';
import InvoiceDetail from './screens/Admin/components/InvoiceDetail';
import Messages from './screens/Admin/components/Messages';
import MessageDetail from './screens/Admin/components/MessageDetail';

const AppRoutes = () => {
    return (
        <Routes>
            <Route index element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/admin/generate" element={<Generate />} />
            <Route path="/admin/invoices" element={<Invoices />} />
            <Route path="/admin/invoice/:numInvoice" element={<InvoiceDetail />} />
            <Route path="/admin/messages" element={<Messages />} />
            <Route path="/admin/messages/:messageId" element={<MessageDetail />} />
            <Route path="/all-services" element={<AllServices />} />
            <Route path="/all-services/:vehicleId" element={<AllServices />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    )
}

export default AppRoutes;