/** USER */
export const SET_USER = 'SET_USER';
export const LOGOUT = 'LOGOUT';


/** PRODUCT TYPES */
export const SET_PRODUCT_TYPES = 'SET_PRODUCT_TYPES'

/** PRODUCTS */
export const SET_PRODUCTS = 'SET_PRODUCTS'

/** VEHICLES */
export const SET_VEHICLES = 'SET_VEHICLES'

/** INVOICES */
export const SET_INVALID_LIST = 'SET_INVALID_LIST'
export const SET_INVOICES = 'SET_INVOICES'

/** MESSAGED */
export const SET_MESSAGES = 'SET_MESSAGES'
