
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Home, Login, AllServices } from './screens';
import Generate from './screens/Admin/components/Generate';
import Invoices from './screens/Admin/components/Invoices';
import InvoiceDetail from './screens/Admin/components/InvoiceDetail';
import Messages from './screens/Admin/components/Messages';
import MessageDetail from './screens/Admin/components/MessageDetail';
import GeneralWrapper from './components/GeneralWrapper';
import PrivacyPolicy from './components/PrivacyPolicy';

const AppRoutes = () => {
    return (
        <Routes>
            <Route index element={<GeneralWrapper><Home /></GeneralWrapper>} />
            <Route path="/login" element={<GeneralWrapper><Login /></GeneralWrapper>} />
            <Route path="/admin/generate" element={<GeneralWrapper><Generate /></GeneralWrapper>} />
            <Route path="/admin/invoices" element={<GeneralWrapper><Invoices /></GeneralWrapper>} />
            <Route path="/admin/invoice/:numInvoice" element={<GeneralWrapper><InvoiceDetail /></GeneralWrapper>} />
            <Route path="/admin/messages" element={<GeneralWrapper><Messages /></GeneralWrapper>} />
            <Route path="/admin/messages/:messageId" element={<GeneralWrapper><MessageDetail /></GeneralWrapper>} />
            <Route path="/all-services" element={<GeneralWrapper><AllServices /></GeneralWrapper>} />
            <Route path="/all-services/:vehicleId" element={<GeneralWrapper><AllServices /></GeneralWrapper>} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    )
}

export default AppRoutes;