import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import { API_getAllMessages, API_deleteMessage } from '../../../../services/messages';
import MenuAdmin from '../MenuAdmin';
import './Messages.css';
import { setMessages } from '../../../../redux/actions/messagedActions';

const Messages = () => {
  const [emailFilter, setEmailFilter] = useState('');
  const [phoneFilter, setPhoneFilter] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const messages = useSelector((state) => state.messagesReducer.messages);
  const { confirm } = Modal;

  const handleGetMessages = async () => {
    const res = await API_getAllMessages();
    if (res?.data?.messages)
      dispatch(setMessages(res.data.messages))
  };

  const showConfirm = (id) => {
    confirm({
      title: '¿Estás seguro de que deseas borrar este mensaje?',
      content: 'Esta acción no se puede deshacer.',
      okText: 'Sí',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteMessage(id)
      },
      onCancel() { }
    });
  };

  const handleDeleteMessage = async (id) => {
    await API_deleteMessage(id);
    handleGetMessages()
  };

  useEffect(() => {
    if (messages?.length === 0) handleGetMessages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (messages?.length === 0)
    return (
      <>
        <MenuAdmin />
        <div className="messages-list-container max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mt-20">
          <h1 className="text-2xl font-bold mb-4 text-main">No existen mensajes</h1>
        </div>
      </>
    );

  const filteredMessages = messages.filter(message =>
    message.email.toLowerCase().includes(emailFilter.toLowerCase()) &&
    message.phone.includes(phoneFilter)
  );

  return (
    <>
      <MenuAdmin />
      <div className="messages-list-container max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mt-20">
        <h1 className="text-2xl font-bold mb-4 text-main">Mensajes de contacto</h1>
        <div className="mb-4 flex flex-col md:flex-row">
          <input
            type="text"
            placeholder="Filtrar por Email"
            className="w-full md:w-1/2 px-4 py-2 border border-gray-300 rounded mb-4 md:mb-0 md:mr-2"
            value={emailFilter}
            onChange={(e) => setEmailFilter(e.target.value)}
          />
          <input
            type="text"
            placeholder="Filtrar por Teléfono"
            className="w-full md:w-1/2 px-4 py-2 border border-gray-300 rounded"
            value={phoneFilter}
            onChange={(e) => setPhoneFilter(e.target.value)}
          />
        </div>
        <ul className="messages-list">
          {filteredMessages
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .map((message) => (
              <li
                key={message._id}
                className="message-item mb-4 p-4 border rounded-lg flex flex-col md:flex-row justify-between"
              >
                <div className="flex flex-col md:flex-row md:justify-between w-full">
                  <div className="flex flex-col">
                    <p className="font-bold">{message.name}</p>
                    <p>{message.email}</p>
                    <p>{message.phone}</p>
                    <p>{moment(message.date, 'Do MMMM YYYY, h:mm:ss a').format('Do MMMM YYYY, h:mm:ss a')}</p>
                  </div>
                  <div className="flex items-center mt-4 md:mt-0">
                    <button
                      className="bg-main text-white px-4 py-2 rounded mr-2"
                      onClick={() => navigate(`/admin/messages/${message._id}`)}
                    >
                      Ver Detalles
                    </button>
                    <button
                      className="bg-additional text-white px-4 py-2 rounded"
                      onClick={() => showConfirm(message._id)}
                    >
                      Borrar
                    </button>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

export default Messages;
