import React from 'react'
import CookiesConsent from './CookiesConsent'

const GeneralWrapper = ({ children}) => {
    return (
        <>
            {children}
            <CookiesConsent />
        </>
    )
}

export default GeneralWrapper