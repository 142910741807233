import React, { useEffect, useState } from 'react'
import {
    PlusCircleOutlined,
    LineChartOutlined,
    MailOutlined,
    LogoutOutlined
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { logout } from '../../../../redux/actions/userActions';
import { Navigate, Link, useLocation } from 'react-router-dom';
import { API_secure } from '../../../../services/auth';

function MenuAdmin() {
    const dispatch = useDispatch();
    const location = useLocation();
    const [shouldGoHome, setShouldGoHome] = useState(false)

    const checkToken = async () => {
        if (!sessionStorage.getItem('t')) {
            setShouldGoHome(true)
        } else {
            const res = await API_secure()
            if (res?.status !== 200) {
                setShouldGoHome(true)
            }
        }
    }

    useEffect(() => {checkToken()}, [])

    const handleLogout = () => {
        dispatch(logout())
        sessionStorage.removeItem('t')
        setShouldGoHome(true)
    }

    if (shouldGoHome) {
        return <Navigate to="/" />;
    }

    return (
        <nav className="fixed top-0 left-0 z-20 w-full bg-white py-2 px-4">
            <div className="container mx-auto flex justify-between items-center sm:space-x-4">
                <Link to="/" className="logo text-main font-bold text-xl hidden md:block">
                    <img src='/assets/logo_letras.png' alt="logo" className='md:w-36 w-24 bg-white p-4' />
                </Link>
                <div className="flex w-full sm:w-auto justify-between gap-2">
                    <Link to="/admin/generate" className={`py-1 px-4 flex flex-row justify-between items-center rounded cursor-pointer ${location.pathname === '/admin/generate' ? 'bg-main text-white hover:text-secondary' : 'text-main hover:bg-gray-200'}`}>
                        <PlusCircleOutlined />
                        <span className="hidden sm:flex mx-1 md:mx-2">Generar</span>
                    </Link>
                    <Link to="/admin/invoices"  className={`py-1 px-4 flex flex-row justify-between items-center rounded cursor-pointer ${location.pathname === '/admin/invoices' ? 'hover:bg-main hover:text-white bg-gray-200 text-main' : 'text-main hover:bg-gray-200'}`}>
                        <LineChartOutlined />
                        <span className="hidden sm:flex mx-1 md:mx-2">Facturas</span>
                    </Link>
                    <Link to="/admin/messages" className={`py-1 px-4 flex flex-row justify-between items-center rounded cursor-pointer ${location.pathname === '/admin/messages' ? 'bg-main text-white hover:text-secondary' : 'text-main hover:bg-gray-200'}`}>
                        <MailOutlined />
                        <span className="hidden sm:flex mx-1 md:mx-2">Mensajes</span>
                    </Link>
                    <span className="text-white py-1 px-4 bg-main rounded cursor-pointer" onClick={handleLogout}>
                        <LogoutOutlined />
                    </span>
                </div>
            </div>
        </nav>
    )
}

export default MenuAdmin
