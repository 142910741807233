import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, Navigate } from 'react-router-dom'
import { API_login, API_secure } from '../../services/auth'
import { setUser } from '../../redux/actions/userActions';

function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(false)
  const isButtonDisabled = !email || !password
  const dispatch = useDispatch()

  const checkToken = async () => {
    if (sessionStorage.getItem('t')?.length > 0) {
      const res = await API_secure()
      if (res.status === 200) {
        setRedirect(true)
      }
    }
  }

  useEffect(() => { checkToken() }, [])

  const onClick = async () => {
    const res = await API_login({ email, password })
    if (res?.status === 200 && res?.data?.token?.length > 0) {
      delete res?.data?.user?.password
      delete res?.data?.user?._id
      dispatch(setUser(res?.data?.user))
      sessionStorage.setItem('t', res?.data?.token)
      setRedirect(true)
    } else {
      setError(true)
    }
  }
  if (redirect) {
    return <Navigate to="/" />;
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <div className="text-center mb-6">
          <img src='assets/logo.png' alt="Logo" className='mx-auto w-36' />
        </div>
        <h2 className="text-3xl font-bold mb-6 text-main text-center">Iniciar Sesión</h2>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Correo Electrónico
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Correo Electrónico"
            className="w-full px-4 py-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
            Contraseña
          </label>
          <input
            type="password"
            id="password"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded"
          />
        </div>
        {
          error && (
            <p className="text-red-500 text-md text-center mb-4">
              El correo electrónico o la contraseña son incorrectos.
            </p>
          )
        }
        <div className="flex items-center justify-between">
          <Link to="/" className="bg-main text-center text-white px-6 py-2 rounded font-bold w-full mr-1">Volver</Link>
          <button
            type="submit"
            className={`bg-main text-white px-6 py-2 rounded font-bold w-full ml-1 ${isButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={isButtonDisabled}
            onClick={onClick}
          >
            Iniciar Sesión
          </button>
        </div>
      </div>
    </div>
  )
}

export default Login