import * as types from '../actions/actionTypes';

const initialState = {
    user: {}
};

const getLocalStorage = () => {
    let state = { ...initialState }
    if (localStorage.getItem('u')) {
        state = JSON.parse(localStorage.getItem('u'))
    }
    return state;
}

export default function reducer(state = getLocalStorage(), action) {
    let newState = { ...state }
    switch (action.type) {
        case types.SET_USER:
            newState = {
                ...state,
                user: action.user,
            };
            localStorage.setItem('u', JSON.stringify(newState))
            return newState

        case types.LOGOUT:
            newState = initialState;
            localStorage.setItem('u', JSON.stringify(newState))
            return newState

        default:
            localStorage.clear()
            localStorage.setItem('u', JSON.stringify(newState))
            return newState
    }
}