import axios from 'axios';
const { BASE_URL = "https://www.limcar.es/api" } = process.env;

export function API_getSomeProducts() {
    return axios.get(`${BASE_URL}/products/searchSome`)
      .then(res => res)
      .catch((err) => err)
}

export function API_getAllProducts() {
    return axios.get(`${BASE_URL}/products/searchAll`)
      .then(res => res)
      .catch((err) => err)
}
